/* Hero Section */
.hero-section {
  position: relative;
  background-image: url('https://i.pinimg.com/736x/fb/3c/13/fb3c1390219fbe5ef05bea0e17da1159.jpg');
  /* background-image: url('https://www.shutterstock.com/image-photo/closeup-peacock-feathers-arranged-around-260nw-2508193349.jpg'); */
  background-size: cover;
  background-position: center;
  height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.522);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.hero-heading {
  font-size: 3.3rem;
  font-weight: 900;
  margin-bottom: 1rem;
}

.hero-text {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  max-width: 700px;
  font-weight: 500;
}

/* Hero Section Responsiveness */
@media (max-width: 1024px) {
  .hero-heading {
    font-size: 2.5rem;
  }

  .hero-text {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: 90vh;
  }

  .hero-heading {
    font-size: 2rem;
  }

  .hero-text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 80vh;
    padding: 10px;
    width: 100%;
  }

  .hero-heading {
    font-size: 1.8rem;
  }

  .hero-text {
    font-size: 0.9rem;
  }
}

/* DONATE FOR TEMPLE Heading CONSTRUCTION */
.details-bg {
  background-image: url('https://img.freepik.com/premium-photo/abstract-light-blue-background-wallpaper-template_1097558-25809.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1px;
}

.head {
  font-size: 2.2em;
  font-weight: 700;
  background-color: #427199;
  padding: 8px;
  border-radius: 8px;
  margin: 6vh 22vw;
  text-align: center;
  color: white;
}

/* DONATE FOR TEMPLE CONSTRUCTION Heading Responsiveness */
@media (max-width: 1024px) {
  .head {
    font-size: 2rem;
    max-width: 70%;
  }
}

@media (max-width: 768px) {
  .details-bg {
    min-height: 30vh;
    padding: 10px;
  }

  .head {
    font-size: 1.8rem;
    max-width: 80%;
  }
}

@media (max-width: 520px) {
  .details-bg {
    min-height: 25vh;
  }

  .head {
    font-size: 1.5rem;
    max-width: 100%;
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  .details-bg {
    min-height: 20vh;
  }

  .head {
    font-size: 1.1rem;
    max-width: 100%;
    padding: 5px 0;
  }
}

/* ISKCON ETAWAH - CONSTRUCTION */
.img-const {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  margin: 5vh 11vw;
  gap: 20px;
}

.const img {
  border: 1px solid black;
  border-radius: 6px;
  width: 25vw;
}

.const-bg {
  background-image: url('https://pub-static.fotor.com/assets/bg/bf9a415f-b758-4c0d-a820-334370772ec3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 11px;
}

/* ISKCON ETAWAH - CONSTRUCTION Responsive */
@media (max-width: 1024px) {
  .img-const {
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;
  }

  .const img {
    width: 100%;
    height: 100%;
  }
}

/* @media (max-width: 768px) {
  .img-const {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
} */

@media (max-width: 500px) {
  .img-const {
    grid-template-columns: 1fr;
    /* max-width: 170%; */
  }

  .const img {
    width: 100%;
  }
}

/* Donation Cards */
.cards-container {
  display: flex;
  justify-content: center;
  gap: 60px;
  padding: 20px;
  /* flex-wrap: wrap; */
  margin-bottom: 5vh;
}

.card {
  background-color: whitesmoke;
  /* border: 1px solid #ddd; */
  border-radius: 11px;
  box-shadow: 11px 11px 11px rgba(0, 0, 0, 0.1);
  /* max-width: 300px; */
  text-align: center;
  overflow: hidden;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  min-height: 40vh;
  width: 26vw;
  transition: transform .4s;
}

.card:hover {
  transform: scale(1.06);
}

.card img {
  width: 100%;
  border: 1px solid gray;
  /* height: 44%; */
  /* object-fit: cover; */
  /* border-radius: 11px; */
  transition: transform .3s;
}

.card img:hover {
  transform: scale(1.06);
}

.card h2 {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #181818;
}

.card p {
  font-size: 1.1rem;
  margin: 8px 0;
  /* color: #666; */
}

.donation-control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

.donation-control button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.donation-control button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.donation-control span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.card .btn {
  width: 100%;
  height: 55px;
  bottom: 0;
  position: relative;
}

.card .btn:hover {
  background-color: #070328cf;
  color: white;
}

/* Donation Cards Responsive */
@media (max-width: 1024px) {
  .card {
    width: 40vw;
  }
}

@media (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .card {
    width: 90%;
    padding: 15px;
  }

  .card h2 {
    font-size: 1.2rem;
  }

  .card p {
    font-size: 1rem;
  }

  .donation-control button {
    padding: 6px 10px;
  }

  .donation-control span {
    font-size: 1rem;
  }
}







.gh {
  text-align: center;
  font-size: 1.8em;
  font-weight: 800;
  color: #260964;
}

.gh2 {
  text-align: center;
  font-size: 1.3em;
  font-weight: 600;
  color: #260964;
}


.donation-container {
  width: 100%;
  padding: 20px;
  text-align: center;
  /* display: grid;
  justify-content: center; */
}

/* .gh {
  font-size: 1.8em;
  font-weight: 800;
  color: #260964;
  margin-bottom: 10px;
}

.gh2 {
  font-size: 1.3em;
  font-weight: 600;
  color: #260964;
  margin-bottom: 20px;
} */

.details {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  margin: 5vh 5vw;
}

.bank, .pytm, .qr {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  background: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.line {
  font-size: 1.3em;
  font-weight: 600;
  color: #260964;
  padding: 10px 0;
}

.qrimg {
  width: 40%;
  max-width: 200px;
  height: auto;
  margin-top: 10px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .details {
    flex-direction: column;
    align-items: center;
  }

  .bank, .pytm, .qr {
    width: 90%;
    margin-bottom: 20px;
  }
}








/* .container {
    display: flex;
    padding: 2vh 5vw;
    background-color: #000230c2;
  }
  
  .container img {
    border-radius: 30%;
    width: 33vw;
  }
  
  .benefits {
    margin: 10vh;
    font-size: 1.2em;
    font-weight: 500;
    color: white;
    font-family: cursive;
  }
  
  p {
    padding: 5px 11px;
  } */