.donation-form {
    text-align: center;
    margin: 11.11vh 0;
}

.donation-form h1 {
    font-size: 3em;
    margin: 50px 22vw;
}

.donation-form h3 {
    font-size: 1.8em;
    color: #494c59;
}

.donation-form p {
    font-size: 1.4em;
    margin: 22px;
    font-weight: 600;
}

.donation-form img {
    height: 56vh;
    width: 100%;
}

form .btn:hover {
    background-color: #070328cf;
    color: white;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    margin: 6vh 6vw;
}

.row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    /* Makes rows wrap to fit smaller screens */
}

.form-group {
    display: flex;
    flex-direction: column;
    width: 23%;
    position: relative;
    /* For precise label positioning */
}

label {
    position: absolute;
    /* Allows precise positioning */
    top: -11px;
    /* Adjust to place label above input */
    left: 5px;
    /* Align label to the left of the input box */
    background-color: #fff;
    /* Add background to label to improve visibility */
    padding: 0 5px;
    font-size: 15px;
    font-weight: bold;
    color: #333;
}

input {
    padding: 10px;
    font-size: 19px;
    border: 1px solid #ccc;
    border-radius: 4px;
}



/* Responsive Design */
@media screen and (max-width: 1024px) {
    .donation-form h1 {
        font-size: 2.5em;
        margin: 40px 12vw;
    }

    .form-group {
        width: 45%;
    }
}

@media screen and (max-width: 768px) {
    .donation-form h1 {
        font-size: 2em;
        margin: 30px 8vw;
    }

    .donation-form h3 {
        font-size: 1.5em;
    }

    .donation-form p {
        font-size: 1.2em;
    }

    .form-group {
        width: 100%;
        /* Full width for smaller screens */
    }

    .form-container {
        gap: 3vh;
    }
}

@media screen and (max-width: 480px) {
    .donation-form h1 {
        font-size: 1.8em;
        margin: 20px 5vw;
    }

    .donation-form h3 {
        font-size: 1.2em;
    }

    .donation-form p {
        font-size: 1em;
    }

    .form-container {
        margin: 4vh 4vw;
    }

    .form-group {
        width: 100%;
    }
}