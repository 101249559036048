* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.header {
  box-shadow: -5px 1px 29px -4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  background-color: #000000a7;
  top: 0;
  position: fixed;
  width: 100%;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.logo img {
  cursor: pointer;
}

nav {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  list-style: none;
  transition: all 0.3s ease-in-out;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links li a {
  padding: 10px 10px;
  font-size: 1.1em;
  font-weight: 500;
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: gray;
}

/* Button styles */
.btn {
  cursor: pointer;
  border: 1px solid #ffffff;
  background-color: #070328;
  color: #fff;
  font-weight: 600;
  font-size: 1em;
  border-radius: 8px;
  padding: 10px 30px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
}

.btn:hover {
  border: 1px solid rgba(107, 107, 107, 0.7);
  background-color: #ffffff;
  color: black;
}

.burger {
  display: none;
  background: none;
  border: none;
  font-size: 1.8em;
  cursor: pointer;
  color: white;
}

.upp {
  padding-top: 11px;
}

/* Responsive */
@media screen and (max-width: 768px) {
  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #000000a7;
    position: absolute;
    top: 70px;
    left: 0;
    padding: 20px;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .burger {
    display: block;
  }
}






/* 
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: white;
    text-align: center;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    margin: 15px 0;
  }

  .burger {
    display: block;
  }
} */