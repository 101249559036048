.footer {
    background-color: #051c26;
    height: 47vh;
    color: white;
    text-align: center;
    font-size: 4em;
    padding-top: 18vh;
    font-weight: 500;
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
    .footer {
        font-size: 4em;
        color: red;
    }
}

@media screen and (max-width: 768px) {
    .footer {
        font-size: 3em;
        padding-top: 18vh;
        color: aqua;
    }
}

@media screen and (max-width: 480px) {
    .footer {
        font-size: 2.8em;
        padding-top: 18vh;
        color: yellowgreen;
    }
}

/* @media screen and (max-width: 430px){
    .footer{
        width: 111%;
        overflow: hidden;
    }
} */